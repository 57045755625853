import React from "react"
import map from "../../assets/images/map.png"

const ContactInfo = () => {
  return (
    <div className="contact-info-area pb-70">
      <div className="map">
        <img src={map} />
      </div>
      <div className="container pt-100 ">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="icon">
                <i className="bx bx-map"></i>
              </div>
              <h3>Our Address</h3>
              <p>
                316 Stephensons Road,
                <br />
                Mount Waverley, <br />
                Melbourne, Victoria{" "}
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <h3>Contact</h3>
              <p>
                Telephone: <a href="tel:+44457895789">0388499992</a>
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div className="contact-info-box">
              <div className="icon">
                <i className="bx bx-time-five"></i>
              </div>
              <h3>Hours of Operation</h3>
              <p>Mon - Sunday 5:00 pm to 9:00 pm</p>
              {/* <p>Mon - Thursday 4:00 pm to 9:00 pm</p>
              <p>Friday - Saturday 11:00 am to 9:30 pm</p>
              <p>Sunday - 11:00 am to 9:00 pm</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
