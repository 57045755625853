import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ContactInfo from "../components/Contact/ContactInfo"
import ContactForm from "../components/Contact/ContactForm"
const Contact = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Contact Us"
        pageTitleText="HAVING A PIZZA PARTY? NEED CATERING FOR A GROUP FUNCTION? WANT TO WORK AT YAMIOS’S? GENERAL COMMENT OR INQUIRY?
GET IN TOUCH!"
        bgImageClass="contact-bg"
      />

      <ContactForm />
      <ContactInfo />
      <Footer />
    </Layout>
  )
}

export default Contact
